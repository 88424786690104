html {
    height: 100%;
    font-weight: 400;
    font-family: 'Noto Sans HK', sans-serif;
    color: #363636;
}

body {
    height: 100%;
    padding: 0;
    margin: 0;
}

h1 {
    font-weight: 400;
}

p {
    font-size: 14px;
    font-weight: 400;
    padding: 0;
    margin: 0;
}

#root {
    height: 100%;
}

/* MAP STYLE */

.gm-style-iw-d{
    overflow: hidden !important;
    max-height: 400px !important;
}
.gm-style-iw.gm-style-iw-c{
    max-height: 400px !important;
}
.gm-style-iw{
    padding: 0 !important;
}
.inner-infobox{
    padding: .6rem
}
.gm-ui-hover-effect{
    background-color: white !important;
    border-radius: 8px;
    opacity: 1;
    top: 10px !important;
    right: 10px !important;
}

.mobileMenuScroll::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE and Edge */
.mobileMenuScroll {
    -ms-overflow-style: none;
}

/* special class to disable ALL text selection inside of wrapper */
.noSelect * {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
